.center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.responsive_img {
    width: 100%;
}

.float-end {
    float: right;
}